import {Component, HostListener, OnDestroy, OnInit, ViewChild, Input, EventEmitter, Output} from '@angular/core';
import {emojisAnimation} from '../../../../animations/emojis.animation';
import {MessageService} from '../../../../services/message.service';
import {ActivatedRoute} from '@angular/router';
import {MessagesModel} from '../../../../models/messages.model';
import {ConversationResponse} from '../../../../models/responses/conversation.response';
import {onMessagesEnter} from '../../../../animations/messages.animation';
import {filter, flatMap} from 'rxjs/operators';
import {ConversationService} from '../../../../services/conversation.service';
import {interval, Subscription} from 'rxjs';
import {ReverseScrollComponent} from '../reverse-scroll/reverse-scroll.component';
import {MessageResponse} from '../../../../models/responses/message.response';
import {AuthService} from '../../../../services/auth.service';
import {EmojiService} from '@ctrl/ngx-emoji-mart/ngx-emoji';
import * as _ from 'lodash';
import {getAttachmentUrl} from '../../../../helpers/mapMessages';
import {NotificationService} from '../../../../services/notification.service';
import {IframeParentService} from 'src/app/services/iframe-parent.service';

@Component({
  templateUrl: './chatbox.component.html',
  styleUrls: ['./chatbox.component.scss'],
  selector: 'app-chatbox',
  animations: [
    emojisAnimation,
    onMessagesEnter
  ]
})
export class ChatboxComponent implements OnInit, OnDestroy {
  loading = true;
  textMessage = '';
  conversationId = null;
  pollSubscription: Subscription;
  isEmojiButtonActive = false;
  loadingOldMessages = false;
  messagesModel = new MessagesModel();

  @Input()
  conversationType: '';

  @Input()
  conversation: ConversationResponse;

  @ViewChild(ReverseScrollComponent, {static: true}) scroller: ReverseScrollComponent;
  @Output()
  hasLoaded = new EventEmitter();

  constructor(
    private messageService: MessageService,
    private activatedRouter: ActivatedRoute,
    private conversationService: ConversationService,
    private authService: AuthService,
    private emoji: EmojiService,
    private notificationService: NotificationService,
    private _iFrameSer: IframeParentService
  ) {
  }

  ngOnInit(): void {
    this.activatedRouter.params.subscribe(params => {
      this.messagesModel = new MessagesModel();
      this.conversationId = params.id;
      this.getConversation(params.id);
    });
  }

  getConversation(id) {
    this.messageService.init(id).getOldMessages().subscribe(messages => {
      this.messagesModel.setOldMessages(messages);
      this.conversationService.setLastRead(this.conversationId);
      this.loading = false;
      this.pollNewMessages();
      this.hasLoaded.emit();
      this.authService.setUser();
      this._iFrameSer.newMessageBadge(this.authService.getUser().newMessageCount);
    });
  }

  onScroll() {
    if (!this.loadingOldMessages) {
      this.loadingOldMessages = true;
      this.messageService.getOldMessages().subscribe(res => {
        this.messagesModel.setOldMessages(res);
        this.loadingOldMessages = false;
      });
    }
  }

  pollNewMessages() {
    this.pollSubscription = interval( (1000 * 5) )
      .pipe(
        flatMap(() => this.messageService.pullNewMessages(this.messagesModel.getLastMessageId())),
        filter(res => res.length > 0)
      )
      .subscribe(res => {
        this.messagesModel.setOldMessages(res);
        this.conversationService.setLastRead(this.conversationId);

        this.authService.setUser();
        this._iFrameSer.newMessageBadge(this.authService.getUser().newMessageCount);
      });
  }

  uploadFile(event) {
    const attachment = {
      fileName: event.target.files[0].name,
      fileType: event.target.files[0].type,
      fileContent: null,
      messageId: null,
      imageUrl: null
    };

    const message = new MessageResponse({
      attachment,
      message: attachment.fileName,
      createdAt: new Date().getTime() / 1000,
      createdBy: this.authService.getUser(),
      id: this.messagesModel.getLastMessageId() + 1,
      loading: true,
      failed: false
    });

    this.messagesModel.addMessage(message);

    this.scroller.scrollBottom();

    const urlReader = new FileReader();

    urlReader.onload = () => {
      attachment.imageUrl = urlReader.result.toString();
    };

    urlReader.readAsDataURL(event.target.files[0]);

    const binaryReader = new FileReader();

    binaryReader.onload = () => {
      attachment.fileContent = btoa(binaryReader.result.toString());
      this.messageService.addMessage(attachment.fileName, attachment).subscribe(res => {
        message.id = res.id;
        message.conversationId = res.conversationId;
        message.attachment.fileContent = getAttachmentUrl(message);
        message.loading = false;
      }, error => {
        message.failed = true;
        message.loading = false;
        this.notificationService.showError(error.error.message, '');
      });
    };

    binaryReader.readAsBinaryString(event.target.files[0]);

    event.target.value = '';
  }

  addMessage() {
    const message = new MessageResponse({
      message: this.textMessage,
      createdAt: new Date().getTime() / 1000,
      createdBy: this.authService.getUser(),
      id: this.messagesModel.getLastMessageId() + 1,
      failed: false
    });
    this.messagesModel.addMessage(message);
    this.scroller.scrollBottom();
    this.messageService.addMessage(this.textMessage).subscribe(res => {
      message.id = res.id;
    }, error => {
      message.failed = true;
      this.notificationService.showError(error.error.message, '');
    });
    this.textMessage = '';
  }

  onKeyup(event) {
    if (event.which === 13 && !event.shiftKey) {
      event.preventDefault();
      this.addMessage();
    }
  }

  addEmoji(event, textArea) {
    if (this.textMessage === '') {
      this.textMessage += event.emoji.native;
      this.addMessage();
    } else {
      this.textMessage += event.emoji.native;
      textArea.focus();
    }
  }

  onEmojiButtonClick(event) {
    event.stopPropagation();
    this.isEmojiButtonActive = !this.isEmojiButtonActive;
  }

  messageOrdering(a, b) {
    return parseInt(a.value.id) > parseInt(b.value.id) ? 1 : -1;
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    this.isEmojiButtonActive = false;
  }

  ngOnDestroy(): void {
    this.pollSubscription.unsubscribe();
  }
}
