import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {slideInAnimation} from './animations/routing';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from './services/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation]
})
export class AppComponent {
  constructor(private translate: TranslateService, private authService: AuthService) {
    this.translate.setDefaultLang(this.authService.getUser().language);
    moment.locale(this.authService.getUser().language);
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
}
