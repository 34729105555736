import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'full-screen', loadChildren: './modules/full-screen/full-screen.module#FullScreenModule',
  },
  {
    path: '', loadChildren: './modules/widget/widget.module#WidgetModule',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
