import {Component, Input, OnInit} from '@angular/core';
import {MessageResponse} from '../../../../models/responses/message.response';
import {AuthService} from '../../../../services/auth.service';
import {UrlObject} from 'url';
import {MessageService} from '../../../../services/message.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {
  @Input() message: MessageResponse;

  @Input()
  conversationType: string;

  constructor(private authService: AuthService, private messageService: MessageService) {
  }

  download() {
    if (this.message.conversationId && !this.message.loading && !this.message.failed) {
      this.messageService.downloadAttachment(this.message.attachment.fileContent).subscribe((res: UrlObject) => {
        const link = document.createElement('a');
        link.target = '_blank';
        link.download = this.message.attachment.fileName;
        link.href = res;
        link.click();
      });
    }
  }

  byCurrentUser() {
    return this.authService.getUserId() === this.message.createdBy.id;
  }
}
