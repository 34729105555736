import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({providedIn: 'root'})
export class IframeParentService {
  private isOpen = false;
  isFullscreen = false;
  fullScreenSubject = new Subject<boolean>();
  isOpenSubject = new Subject<boolean>();

  constructor(private router: Router, private modalService: NgbModal) {
    window.addEventListener('message', this.onIframeOpenClose.bind(this));
  }

  changeWidth() {
    this.isFullscreen = !this.isFullscreen;
    window.parent.postMessage('changeChatWidth', '*');
    this.fullScreenSubject.next(this.isFullscreen);
  }

  getIsFullScreen() {
    return this.isFullscreen;
  }

  getIsFullScreenObservable() {
    return this.fullScreenSubject.asObservable();
  }

  close() {
    window.parent.postMessage('closeChat', '*');
  }

  newMessageBadge(new_message_count) {
    window.parent.postMessage('newMessage__' + new_message_count, '*');
  }

  getIsOpen() {
    return this.isOpen;
  }

  onIframeOpenClose(message) {
    if (typeof message.data.message_key !== 'undefined') {
      if (message.data.message_key === 'openCloseButtonClicked') {
        this.isOpen = !this.isOpen;
        this.isOpenSubject.next(this.isOpen);
      } else if (message.data.message_key === 'setAccountCSS') {
        if (typeof message.data.account_css_url !== 'undefined' && message.data.account_css_url !== '') {
            const acc_style = document.createElement('link');
            acc_style.id = 'com_center_account_css';
            acc_style.rel = 'stylesheet';
            acc_style.type = 'text/css';
            acc_style.href = message.data.account_css_url;
            document.head.appendChild(acc_style);
        }
      } else if (message.data.message_key === 'startConversation') {
        if (!this.isOpen) {
          this.isOpen = !this.isOpen;
        }
        this.isOpenSubject.next(this.isOpen);

        this.router.navigate(['']);
        if (typeof message.data.conversation_uid !== 'undefined') {
          if (this.modalService.hasOpenModals()) {
            this.modalService.dismissAll();
          }
          this.router.navigate(['/conversations', message.data.conversation_uid]);
        } else if (!this.modalService.hasOpenModals()) {
          if (typeof message.data.related_data !== 'undefined') {
            this.router.navigate(['/newconversation', message.data.related_data]);
          } else {
            this.router.navigate(['/newconversation']);
          }
        }
      }
    }
  }

  getIsOpenObservable(): Observable<boolean> {
    return this.isOpenSubject.asObservable();
  }
}
