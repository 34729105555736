import { AccountResponce } from '../../../../models/responses/account.response';
import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../../services/user.service';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ConversationService } from '../../../../services/conversation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { fadeInOut } from '../../../../animations/FadeInOut';
import { HelpService } from 'src/app/services/help.service';
import { AuthService } from '../../../../services/auth.service';
import { NotificationService } from '../../../../services/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-new-conversation',
  templateUrl: './create-new-conversation.component.html',
  styleUrls: ['./create-new-conversation.component.scss'],
  animations: [
    fadeInOut
  ]
})
export class CreateNewConversationComponent implements OnInit {
  @ViewChild('content', { static: true }) content: ElementRef;
  agents = [];
  teamMembers = [];
chatTeamMembers  =[];
  loading = false;
  form: FormGroup;
  title: string;
  conversationTypeSelected = 'team';
  @Input() invert = false;
  nextClicked = false;
  chatDetails = false;
  chatTitle = '';
  chatDescription = '';
  shouldGoNext = false;
  insidePop = true;
  noBack = false;
  users = [];
  files = [];
  taxConsultantId = null;
  related_data = {};
  related_object_uid = 0;
  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private userService: UserService,
    private conversationService: ConversationService,
    private router: Router,
    private _helpService: HelpService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private translate: TranslateService,
  ) {
    authService.getAccountsData().then((account) => {
      const taxCon = Object.values(account.users).find(u => u.role === 'tax_consultant');
      this.taxConsultantId = taxCon ? taxCon.id : 0;
    });
authService.getAccountsData().then((account) => {
      this.chatTeamMembers = Object.values(account.users).filter(u => u.role === 'member' && u.id !== this.authService.getUserId().toString());
    });

  }

  receiveMessage(data) {
    this.users = data.teamMember.teamMemberId;
    this.conversationTypeSelected = data.type;
    this.shouldGoNext = this.conversationTypeSelected === 'team' || this.conversationTypeSelected === 'clients';
    this.chatTitle = data.chatTitle;
    this.chatDescription = data.chatDescription;
    this.files = data.files;
    this.files = data.files;
  }

  ngOnInit(): void {
    this.userService.getAgents().subscribe(agents => this.agents = agents);
    this.form = new FormGroup({
      title: new FormControl('', [
        Validators.required,
      ]),
      teamMembers: new FormControl([]),
      supportAgents: new FormControl([])
    });

    this.activatedRoute.params.subscribe(params => {
      this.related_object_uid = params.object_uid ? params.object_uid : 0;
      this.title = params.title ? params.title : '';
      this.related_data = {
        object_key: params.object_key ? params.object_key : '',
        object_uid: params.object_uid ? params.object_uid : '',
        object_name: params.object_name ? params.object_name : '',
        object_deeplink: params.object_deeplink ? params.object_deeplink : '',
      };
    });

    this.modalService.open(this.content, { ariaLabelledBy: 'modal-basic-title', centered: true, keyboard: false });
  }

  openModal(content) {
    // this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true });
  }

  startConversation() {
    this.loading = true;
    if (this.conversationTypeSelected === 'support') {
      this.users = [this.agents[0].id];
    } else if (this.conversationTypeSelected === 'consultant') {
      this.users = [this.taxConsultantId];
    }
if(this.users.length > 0){
   this.conversationService.startNewConversation(this.users, this.files, this.chatTitle, this.chatDescription, this.related_data).subscribe(id => {
 
      this.loading = false;
      this.modalService.dismissAll();
      this.router.navigate(['conversations', id]);
    }, error => {
      this.loading = false;
      this.notificationService.showError(this.translate.instant('SYSTEM_ERROR'), '');
    });
}else{
   this.loading = false;
   this.translate.get('SELECT-PERSON-TO-CHAT').subscribe((res) => {
   this.notificationService.showError( res, '');
 
});
}
  }

  isValid() {
    const value = this.form.value; 
    return this.form.valid && (value.teamMembers.length > 0 || value.supportAgents.length > 0);
  }

  next() {
	if(this.chatTeamMembers.length  <= 0 && this.conversationTypeSelected == 'team'){
	   this.translate.get('NO-TEAM-MEMBER-AVAILABLE-TO-CHAT').subscribe((res) => {
	        this.notificationService.showError( res, '');
 	    }); 
  	}
	else{
          this.nextClicked = true;
	}
}

  nextOpt() {
    this.chatDetails = true;
  }
  onClose() {
    this.modalService.dismissAll();
    // Allow fade out animation to play before navigating back
    this.router.navigate(['..']);
  }
}
