import {Component, OnInit, EventEmitter, Output, Input} from '@angular/core';
import {UserService} from 'src/app/services/user.service';
import {AuthService} from '../../../../services/auth.service';
import {map} from 'rxjs/operators';
import {MessageResponse} from "../../../../models/responses/message.response";
import {getAttachmentUrl} from "../../../../helpers/mapMessages";
import {MessagesModel} from "../../../../models/messages.model";
import {MessageService} from "../../../../services/message.service";

@Component({
  selector: 'app-chat-type',
  templateUrl: './chat-type.component.html',
  styleUrls: ['./chat-type.component.scss']
})
export class ChatTypeComponent implements OnInit {
  @Output() messageEvent = new EventEmitter();
  @Input() nextClicked = false;
  @Input() chatDetails = false;
  @Input() chatTitle = '';
  @Input() chatDescription = '';
  fileLabel = 'upload upto 5 files';
  @Input() relatedObjectUid = 0;
  isTaxConsultant = false;
  maxFileExceed   = false;
  taxConsultantId = null;
  conversationTypeSelected = 'team';
    messagesModel = new MessagesModel();

  teamMembers = [];
  clients = [];
  files = [];

  selectMenuUsers = [];

  teamMemberData = {
    teamMemberName: [],
    teamMemberId: []
  };

  @Input()
  chatMembers = [];

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private messageService: MessageService,
  ) {

  }

  ngOnInit() {

    this.authService.getAccountsData().then((account) => {
      this.clients = Object.values(account.users).filter(u => u.role === 'client').sort( (a, b) => a.name.localeCompare(b.name) );
    });
    this.authService.getAccountsData().then((account) => {
      this.teamMembers = Object.values(account.users).filter(u => u.role === 'member' && u.id !== this.authService.getUserId().toString());
      this.selectConversationType('team');
    });
    this.authService.getAccountsData().then((account) => {
      const taxCon = Object.values(account.users).find(u => u.role === 'tax_consultant');
      this.taxConsultantId = taxCon ? taxCon.id : false;
    });
    this.isTaxConsultant = this.authService.getUser().isTaxConsultant;

  }

  selectConversationType(type) {
    if( type === 'team') {
      this.selectMenuUsers = this.teamMembers;
    }
    if(type === 'clients') {
      this.selectMenuUsers = this.clients;
    }
    const data = {
      type,
      teamMember: this.teamMemberData,
      chatTitle:this.chatTitle,
      chatDescription:this.chatDescription,
      files:this.files
    };
    this.messageEvent.emit(data);
    this.conversationTypeSelected = type;
  }
  ChatTitle(value){
    this.chatTitle = value;
    var type = this.conversationTypeSelected;
    const data = {
      type:this.conversationTypeSelected,
      teamMember: this.teamMemberData,
      chatTitle:this.chatTitle,
      chatDescription:this.chatDescription,
      files:this.files
    };
    this.messageEvent.emit(data);
  }
  ChatDescription(value){
    this.chatDescription = value;

    var type = this.conversationTypeSelected;
    const data = {
      type:this.conversationTypeSelected,
      teamMember: this.teamMemberData,
      chatTitle:this.chatTitle,
      chatDescription:this.chatDescription,
      files:this.files
    };
    this.messageEvent.emit(data);
  }
  Upload(event) {

    let length = event.target.files.length;
    if (length > 5) {
      this.maxFileExceed = true;
    } else {
      this.maxFileExceed = false;
      if(length==1){
        this.fileLabel=event.target.files[0].name;
      } else {
        this.fileLabel = length+' attachments';
      }

      for (let i = 0; i < length; i++) {
          const attachment = {
              fileName: event.target.files[i].name,
              fileType: event.target.files[i].type,
              fileContent: null,
              messageId: null,
              imageContent: null,
              createdAt: new Date().getTime() / 1000,
          };


          //this.scroller.scrollBottom();

          const urlReader = new FileReader();

          urlReader.onload = () => {
              attachment.imageContent = urlReader.result.toString();
          };

          urlReader.readAsDataURL(event.target.files[i]);

          const binaryReader = new FileReader();


        binaryReader.onload = () => {
          attachment.fileContent = btoa(binaryReader.result.toString());
        };
          binaryReader.readAsBinaryString(event.target.files[i]);
        this.files[i] = attachment;
      }
    }
  }
  teamMember(name, id, event) {
    if (event.target.checked) {
      this.teamMemberData.teamMemberName.push(name);
      this.teamMemberData.teamMemberId.push(id);
    } else {
      this.teamMemberData.teamMemberName.map((member, index) => {
        if (member === name) {
          this.teamMemberData.teamMemberName.splice(index, 1);
        }
      });
      this.teamMemberData.teamMemberId.map((memberId, index) => {
        if (memberId === id) {
          this.teamMemberData.teamMemberId.splice(index, 1);
        }
      });
    }
    this.selectConversationType(this.conversationTypeSelected);
  }
}
